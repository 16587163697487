import {  Box, Button, IconButton, Paper, TextField, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import InformationEdit from "../../components/layout/InformationEdit";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import Card from "../../components/layout/Card";
import Split from "../../components/layout/Split";
import Spaced from "../../components/layout/Spaced";
import {ReactComponent as MollieConnect} from "../../images/MollieConnect.svg"
import { apiClient } from "../../functions/Login";
import { useEffect } from "react";
import { Body_update_subsidiary_users_subsidiaries__company_id__patch, CancelablePromise, CompanyEdit, CompanyOut, MollieOrganizationOut, SubsidiaryEdit, TenantStyleEdit } from "../../assets";
import { ColorPicker, ColorPickerProps, GetProp, Upload, UploadFile } from "antd";
import ButtonOutlined from "../../components/buttons/ButtonOutlined";
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import ButtonFull from "../../components/buttons/ButtonFull";
import Mollie from "../../components/modals/mollie/Mollie";
import ButtonText from "../../components/buttons/ButtonText";
import MollieDelete from "../../components/modals/mollie/MollieDelete";
import { useParams } from "react-router-dom";
import MollieSuccess from "../../components/modals/mollie/MollieSuccess";
import MollieFail from "../../components/modals/mollie/MollieFail";
import '../../css/upload.css'

export default function CompanyInformation() {
    const mollie_status = String(useParams().mollie_status);
    
    useSignals();

    const company_name: Signal<string> = useSignal('');
    const coc_number: Signal<string> = useSignal('');
    const vat_number: Signal<string> = useSignal('');
    const billing_email: Signal<string> = useSignal('');

    const editing_mollie: Signal<boolean> = useSignal(false);
    const openMollie: Signal<boolean> = useSignal(false);
    const mollie_information: Signal<MollieOrganizationOut | null> = useSignal(null)
    const openMollieDelete: Signal<boolean> = useSignal(false);

    const email: Signal<string> = useSignal('');
    const phone_number: Signal<string> = useSignal('');
    const contact_person: Signal<string> = useSignal('');
    const phone_number_contact: Signal<string> = useSignal('');

    const editing_location: Signal<boolean> = useSignal(false);
    const postal_code: Signal<string> = useSignal('');
    const house_number: Signal<string> = useSignal('');
    const number_suffix: Signal<string> = useSignal('');
    const street: Signal<string> = useSignal('');
    const city: Signal<string> = useSignal('');

    const company_info: Signal<CompanyOut | undefined> = useSignal(undefined)
    
    type Color = GetProp<ColorPickerProps, 'value'>;
    const editing_style: Signal<boolean> = useSignal(false);
    const fileList: Signal<UploadFile[]> = useSignal([]);
    const primary_color: Signal<Color> = useSignal('');
    const secondary_color: Signal<Color> = useSignal('');
    const tertiary_color: Signal<Color> = useSignal('');
    const text_color: Signal<Color> = useSignal('');

    const uploadLogo = async (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file} = options;

        apiClient().userManagement.uploadLogoToS3UsersUploadLogoImagePost({file: file as Blob}).then((r) => {
            if (onSuccess) onSuccess('OK');
        }).catch((error: UploadRequestError) => {
            if (onError) onError(error, 'Er is iets fout gegaan, probeer het opnieuw');
        })
    }

    const saveLocation = () => {
        const body = {
            edit_subsidiary: {
                delivery_address: {
                    street: street.value,
                    number: +house_number.value,
                    number_suffix: number_suffix.value,
                    postal_code: postal_code.value,
                    city: city.value
                } 
            } as SubsidiaryEdit
           
        } as Body_update_subsidiary_users_subsidiaries__company_id__patch

        apiClient().userManagement.updateSubsidiaryUsersSubsidiariesCompanyIdPatch(company_info.value?.id!, body).then((r) => {           
            editing_location.value = false;
        })
    }

    const editingAddress = useComputed(() => {
        return postal_code.value.length > 0 || house_number.value.length > 0 || house_number.value.length > 0 || street.value.length > 0 || city.value.length > 0
    })

    const urlAddress = useComputed(() => {
        let space = '%20'
        return street.value + space + house_number.value + space + number_suffix.value + space + city.value;
    })
    
    useEffect(() => {
        apiClient().userManagement.getUltimateCompanyLocationUsersUltimateCompanyGet().then((r) => {
            company_info.value = r

            street.value = r.delivery_address.street ? r.delivery_address.street : '';
            house_number.value = r.delivery_address.number ? r.delivery_address.number.toString() : '';
            number_suffix.value = r.delivery_address.number_suffix ? r.delivery_address.number_suffix : '';
            postal_code.value = r.delivery_address.postal_code ? r.delivery_address.postal_code : '';
            city.value = r.delivery_address.city ? r.delivery_address.city : '';

            company_name.value = r.name;
            coc_number.value = r.coc_number ? r.coc_number : '';
            vat_number.value = r.VAT_number ? r.VAT_number : '';
            billing_email.value = r.invoice_email ? r.invoice_email : '';

            email.value = r.email;
            phone_number.value = r.phone_number ? r.phone_number : '';
            contact_person.value = r.contact_full_name ? r.contact_full_name : '';
            phone_number_contact.value = r.contact_phone_number ? r.contact_phone_number : '';
        })
        apiClient().userManagement.getUltimateCompanyPaymentOrganizationUsersUltimateCompanyMollieGet().then((r) => {
            mollie_information.value = r;
        })
        apiClient().userManagement.getTenantStyleUsersTenantStyleGet().then((r) => {
            primary_color.value = r.primary_color;
            secondary_color.value = r.secondary_color;
            tertiary_color.value = r.tertiary_color;
            text_color.value = r.primary_text_color;
            if ( r.logo_image )
                fileList.value = [{
                    uid: '1',
                    name: 'logo.png',
                    status: 'done',
                    url: r.logo_image
                }]
        })  
    }, [])
    
    return(
        <ThemeProvider theme={theme}>
            <Mollie open={openMollie} company_id={company_info.value?.id}/>
            <MollieDelete open={openMollieDelete}/>
            {
                mollie_status === 'geslaagd' &&
                <MollieSuccess />
            }
            {
                mollie_status === 'mislukt' &&
                <MollieFail />
            }
            <InformationEdit 
                title='Bedrijfsgegevens'
                fields={[
                    {Icon: BusinessOutlinedIcon, title: 'Bedrijfsnaam', value: company_name},
                    {Icon: WorkOutlineOutlinedIcon, title: 'KVK-nummer', value: coc_number},
                    {Icon: AccountBalanceOutlinedIcon, title: 'btw-nummer', value: vat_number},
                    {Icon: EuroSymbolOutlinedIcon, title: 'Facturatie emailadres', value: billing_email},
                ]}
                editEndpoint={(params: {}): CancelablePromise<any> => {return apiClient().userManagement.updateSubsidiaryUsersSubsidiariesCompanyIdPatch(company_info.value?.id!, { edit_subsidiary: {company: params as CompanyEdit}} as Body_update_subsidiary_users_subsidiaries__company_id__patch)}}
                requestBody={{
                    name: company_name,
                    coc_number: coc_number,
                    VAT_number: vat_number,
                    invoice_email: billing_email
                }}
            />
            <Card 
                title='Mollie account koppeling'
                button={
                    mollie_information.value !== null ? 
                        editing_mollie.value  ?
                        <IconButton onClick={() => {editing_mollie.value = false}}>
                            <CloseOutlinedIcon />
                        </IconButton>
                        :
                        <>
                            <ButtonFull Icon={OpenInNewOutlinedIcon} text='Open mijn Mollie dashboard' onClick={() => {window.open(mollie_information.value?.dashboard_url!, '_blank')}}/>
                            <IconButton onClick={() => {editing_mollie.value = true}}>
                                <EditOutlinedIcon />
                            </IconButton>
                        </>
                        
                        :
                    <></>
                }
                children={
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        {
                            mollie_information.value !== null ? 
                                editing_mollie.value  ?
                                <>
                                    <Typography variant='bodyLarge'>
                                        Jouw account is gekoppeld aan Mollie account organisatie {mollie_information.value.ext_organization_name}, met organisatie id [{mollie_information.value.ext_organization_id}].
                                    </Typography>
                                </>
                                :
                                <>
                                    <Typography variant='bodyLarge'>
                                        Jouw account is gekoppeld aan Mollie account organisatie {mollie_information.value.ext_organization_name}, met organisatie id [{mollie_information.value.ext_organization_id}].
                                    </Typography>
                                    <ButtonText text='Mollie account koppeling verwijderen' onClick={() => {openMollieDelete.value = true}}/>
                                </>
                            :
                            <>
                                <Typography variant='bodyLarge'>
                                    Koppel jouw Mollie account om webshops aan te maken en betalingen van jouw klanten te kunnen ontvangen.
                                </Typography>
                                <MollieConnect style={{cursor: 'pointer'}} onClick={() => {openMollie.value = true}}/>
                            </>
                        }
                       
                    </Box>
                }
            />
            
            <InformationEdit 
                title='Contactgegevens'
                fields={[
                    {Icon: EmailOutlinedIcon, title: 'Emailadres', value: email},
                    {Icon: LocalPhoneOutlinedIcon, title: 'Telefoonnummer algemeen', value: phone_number},
                    {Icon: PersonOutlineOutlinedIcon, title: 'Primaire contactpersoon', value: contact_person},
                    {Icon: PhoneIphoneOutlinedIcon, title: 'Telefoonnummer contactpersoon', value: phone_number_contact},
                ]}
                editEndpoint={(params: {}): CancelablePromise<any> => {return apiClient().userManagement.updateSubsidiaryUsersSubsidiariesCompanyIdPatch(company_info.value?.id!, { edit_subsidiary: {company: params as CompanyEdit}} as Body_update_subsidiary_users_subsidiaries__company_id__patch)}}
                requestBody={{
                    email: email,
                    phone_number: phone_number,
                    contact_full_name: contact_person,
                    contact_phone_number: phone_number_contact
                }}
            />
            <Card 
                title='Hoofdvestiging'
                button={
                    editing_location.value ?
                    <>
                        <ButtonFull type="submit" form='locationEdit' text='Wijzigen opslaan' onClick={() => {}}/>
                        <IconButton onClick={() => {
                            apiClient().userManagement.getUltimateCompanyLocationUsersUltimateCompanyGet().then((r) => {
                                company_info.value = r
                
                                street.value = r.delivery_address.street ? r.delivery_address.street : '';
                                house_number.value = r.delivery_address.number ? r.delivery_address.number.toString() : '';
                                number_suffix.value = r.delivery_address.number_suffix ? r.delivery_address.number_suffix : '';
                                postal_code.value = r.delivery_address.postal_code ? r.delivery_address.postal_code : '';
                                city.value = r.delivery_address.city ? r.delivery_address.city : '';
                
                                editing_location.value = false;
                            })
                        }}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </>                   
                    :
                    <IconButton onClick={() => {editing_location.value = true}}>
                        <EditOutlinedIcon />
                    </IconButton>
                }
                children={
                    <Split left={
                        <>
                        {
                            editing_location.value ?
                            <form id='locationEdit' onSubmit={(e) => {e.preventDefault(); saveLocation()}} style={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                                <TextField required={editingAddress.value} value={postal_code.value} onChange={(e) => {postal_code.value = e.currentTarget.value}} fullWidth label="Postcode" variant="outlined" />
                                <Split 
                                    left={
                                        <TextField required={editingAddress.value} type='tel' value={house_number.value} onChange={(e) => {house_number.value = e.currentTarget.value}} label="Huisnummer" variant="outlined"/> 
                                    }
                                    right={
                                        <TextField value={number_suffix.value} onChange={(e) => {number_suffix.value = e.currentTarget.value}} label="Toevoeging" variant="outlined"/>
                                    }
                                />
                                <TextField required={editingAddress.value} value={street.value} onChange={(e) => {street.value = e.currentTarget.value}} fullWidth label="Straat" variant="outlined"/>
                                <TextField required={editingAddress.value} value={city.value} onChange={(e) => {city.value = e.currentTarget.value}} fullWidth label="Plaats" variant="outlined"/>
                            </form>
                            :
                            <Box sx={{display: 'flex', gap: '8px'}}>
                                <RoomOutlinedIcon />
                                <Spaced orientation="column" children={
                                    <>
                                    <Typography variant="bodyLarge">
                                        {street.value} {house_number.value}{number_suffix.value}
                                    </Typography>
                                    <Typography variant="bodyLarge">
                                        {postal_code.value}
                                    </Typography>
                                    <Typography variant="bodyLarge">
                                        {city.value}
                                    </Typography>
                                    </>
                                }
                                />
                            </Box>
                        }
                        </>
                    } 
                    right={
                        <iframe
                            title="Hoofdvestiging locatie"
                            height={'250px'}
                            width={'100%'}
                            style={{borderRadius: '12px', border: 0}}
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                &q=${urlAddress}}`}
                        >
                        </iframe>
                    }
                    />   
                }
            />
             <Card 
                title='Huisstijl'
                button={
                    editing_style.value ?
                    <>
                        <ButtonFull text='Wijzigingen opslaan' onClick={() => {
                            apiClient().userManagement.updateTenantStyleUsersTenantStylePatch({
                                primary_color: primary_color.value.toString(),
                                secondary_color: secondary_color.value.toString(),
                                tertiary_color: tertiary_color.value.toString(),
                                text_color: text_color.value.toString(),
                            } as TenantStyleEdit).then(() => {editing_style.value = false})
                        }}/>
                        <IconButton onClick={() => {
                            apiClient().userManagement.getTenantStyleUsersTenantStyleGet().then((r) => {
                                primary_color.value = r.primary_color;
                                secondary_color.value = r.secondary_color;
                                tertiary_color.value = r.tertiary_color;
                                text_color.value = r.primary_text_color;
                                if ( r.logo_image )
                                    fileList.value = [{
                                        uid: '1',
                                        name: 'logo.png',
                                        status: 'done',
                                        url: r.logo_image
                                    }]
                                editing_style.value = false;
                                })  
                            }}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </>
                   
                    :
                    <IconButton onClick={() => {editing_style.value = true}}>
                        <EditOutlinedIcon />
                    </IconButton>
                }
                children={
                    <Split left={
                        <Box key={fileList.value.length} sx={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                        <Typography variant='titleMedium' sx={{fontWeight: 700}}>
                            Logo
                        </Typography>
                        <Upload
                            disabled={!editing_style.value}
                            listType="picture-card"
                            fileList={fileList.value}
                            key={fileList.value.length}
                            customRequest={(e) => uploadLogo(e)}
                            className='customSizedUpload'
                        >
                            {fileList.value.length > 0 ? null : <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>}
                        </Upload>
                        <Typography variant='titleMedium' sx={{fontWeight: 700}}>
                            Kleuren
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px'}}>
                            <Typography variant='bodyLarge'>
                                Webshop headers:
                            </Typography>
                            <ColorPicker key={primary_color.value.toString()} disabled={!editing_style.value} value={primary_color.value} onChange={(e) => primary_color.value = e.toHexString()} />
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px'}}>
                            <Typography variant='bodyLarge'>
                                Webshop achtergrond:
                            </Typography>
                            <ColorPicker key={secondary_color.value.toString()}  disabled={!editing_style.value} value={secondary_color.value} onChange={(e) => secondary_color.value = e.toHexString()} />
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px'}}>
                            <Typography variant='bodyLarge'>
                                Actieknoppen:
                            </Typography>
                            <ColorPicker key={tertiary_color.value.toString()}  disabled={!editing_style.value} value={tertiary_color.value} onChange={(e) => tertiary_color.value = e.toHexString()} />
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px'}}>
                            <Typography variant='bodyLarge'>
                                Tekstkleur actieknoppen:
                            </Typography>
                            <ColorPicker key={text_color.value.toString()}  disabled={!editing_style.value} value={text_color.value} onChange={(e) => text_color.value = e.toHexString()} />
                        </Box>
                        </Box>
                    } 
                    right={
                        <Paper variant='outlined' sx={{backgroundColor: 'fff', borderRadius: '12px', border: '1px solid #0f0f0f', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{backgroundColor: primary_color.value.toString(), height: '100px', width: '100%', display: 'flex', px: '64px', justifyContent: 'space-between', alignItems: 'center'}}>
                                <img src={fileList.value.length > 0 ? fileList.value[0].url : ''} alt="" style={{width: 'auto', height: 'auto', maxWidth: '100px', maxHeight: '65px', alignContent: 'center'}}/>
                            </Box>
                            <Box sx={{backgroundColor: secondary_color.value.toString(), width: '100%', p: '64px', gap: '32px', flexDirection: 'column', display: 'flex'}}>
                                <Typography variant='headlineLarge'>
                                    Drive-Thru webshop
                                </Typography>
                                <Typography variant='bodyLarge'>
                                    Afhalen van jouw bestelling kan op 31 december tussen 09:00 en 18:00 uur in onze drive-thru
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                    <Box sx={{ display: 'flex'}}>
                                        <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                                        <Typography variant="bodyLarge">
                                            Vooraf online bestellen
                                        </Typography>
                                    </Box> 
                                    <Box sx={{ display: 'flex'}}>
                                        <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                                        <Typography variant="bodyLarge">
                                            Af te halen op jouw eigen gekozen moment
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex'}}>
                                        <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                                        <Typography variant="bodyLarge">
                                            Gemakkelijk en snel af te halen
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{width: '300px', display: 'flex', flexDirection: 'column'}}>
                                    <Button variant="contained" sx={{borderRadius: '20px', pointerEvents: 'none', backgroundColor: tertiary_color.value.toString()}}>
                                        <Typography variant="labelLarge" sx={{px: '12px', color: text_color.value.toString()}}>
                                            Bestel en reserveer jouw tijdslot
                                        </Typography> 
                                    </Button>
                                    <Typography variant="bodySmall" sx={{py: '12px', alignSelf: 'center'}}>
                                        Voor tijdsloten geldt: VOL = VOL
                                    </Typography> 
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', p: '64px'}}>
                                <Box sx={{display: 'flex', width: '100%'}}>
                                    <Box sx={{backgroundColor: primary_color.value.toString() + '50', p: '8px', borderRadius: '32px', width: 'fit-content', height: 'fit-content', display: 'flex', justifyContent: 'center'}}>
                                        <ShoppingCartOutlinedIcon sx={{color: primary_color.value.toString()}} />
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="headlineSmall" sx={{pt: '4px', pl: '16px'}}>
                                            Online bestellen
                                        </Typography> 
                                        <Typography variant="bodyLarge" sx={{pt: '16px', alignSelf: 'end', pl: '16px'}}>
                                            Plaats jouw online bestelling en kies een tijdslot op een moment dat het jou uitkomt. Zo spreiden wij de drukte.
                                        </Typography> 
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    }
                    />   
                }
            />
        </ThemeProvider>
    )
}