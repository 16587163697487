import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import VerticalStepper from "../modals/stepper/VerticalStepper";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import ButtonFull from "../buttons/ButtonFull";
import ButtonOutlined from "../buttons/ButtonOutlined";
import { apiClient } from "../../functions/Login";
import { ArtikelNaamOut, SalesProductGroupIn, SalesProductGroupNaamOut } from "../../assets";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { ConfigProvider, DatePicker, Upload, UploadFile } from "antd";
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import locale from 'antd/es/date-picker/locale/nl_NL';
import { Dayjs } from "dayjs";
import ProductsToGroup from "./ProductsToGroup";
import '../../css/upload.css'

interface ProductGroupAddProps {
    open: Signal<boolean>
}

const ProductGroupAdd: React.FC<ProductGroupAddProps> = ({open}) => {
    useSignals();

    const group_name: Signal<string> = useSignal('');
    const group_code: Signal<string> = useSignal('');
    const groups: Signal<SalesProductGroupNaamOut[]> = useSignal([]);
    const selectedGroup: Signal<string> = useSignal('');
    const img_url: Signal<string> = useSignal('');
    const activeFrom: Signal<Dayjs | null> = useSignal(null);
    const activeTill: Signal<Dayjs | null> = useSignal(null);
    const selectedProducts: Signal<ArtikelNaamOut[]> = useSignal([]);

    const fileList: Signal<UploadFile[]> = useSignal([]);
    const uploadLogo = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file} = options;

        apiClient().userManagement.uploadLogoToS3UsersUploadLogoImagePost({file: file as Blob}).then((r) => {
            if (onSuccess) onSuccess('OK');
            img_url.value = r;
        }).catch((error: UploadRequestError) => {
            if (onError) onError(error, 'Er is iets fout gegaan, probeer het opnieuw');
        })
    }

    const reset = () => {
        batch(() => {
           group_name.value = '';
           group_code.value = '';
           selectedGroup.value = '';
           img_url.value = '';
           activeFrom.value = null;
           activeTill.value = null;
           selectedProducts.value = [];
           fileList.value = [];
        })
    }

    useEffect(() => {
        apiClient().itemManagement.getAllSalesProductGroupsItemsSalesProductGroupsGet().then((r) => {
            groups.value = r.items;
        })
    }, [])

    const createGroup = (activeStep: Signal<number>) => {
        const body ={
            is_active: true,
            sales_product_group_name: group_name.value,
            sales_product_group_code: group_code.value,
            sales_product_group_image: img_url.value,
            parent_id: selectedGroup.value !== '' ? selectedGroup.value : undefined,
            active_from_date: activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            active_to_date: activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            products: selectedProducts.value.map(p => p.id)
        } as SalesProductGroupIn
       apiClient().itemManagement.createSalesProductGroupItemsCreateSalesProductGroupPost(body).then((r) => {
            activeStep.value += 1;
       }).catch()
    }

    const finalContent = <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', height: '100%'}}>
        <Typography variant='bodyLarge'>
            De groep {group_name} is toegevoegd.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', mt: 'auto'}}>
            <ButtonFull text='Sluiten' type='submit' onClick={() => {open.value = false; reset();}} width='100%'/>
            <ButtonOutlined text='Sluiten en nog een groep toevoegen' type='submit' onClick={() => {reset()}} width='100%'/>
        </Box>
    </Box>

    const steps = [
        {
            label: 'Groepsnaam en -code',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <TextField required label="Naam groep" variant="outlined" value={group_name.value} onChange={(e) => {group_name.value = e.currentTarget.value}}/>
                <TextField required label="Groepscode" variant="outlined" value={group_code.value} onChange={(e) => {group_code.value = e.currentTarget.value}}/>
                <FormControl>
                    <InputLabel id="group-select-label">Is subgroep van</InputLabel>
                    <Select
                        labelId='group-select-label'
                        IconComponent={ExpandMoreOutlinedIcon}
                        value={selectedGroup.value}
                        label={'Is subgroep van'}
                        MenuProps={{ style: {maxHeight: '300px'}}}
                        onChange={(e) => {selectedGroup.value = e.target.value}}
                        sx={{width: '100%', maxHeight: '60px', borderRadius: '4px'}}
                    >
                         <MenuItem value="">
                            <em>Eigen groep</em>
                        </MenuItem>
                        {
                            groups.value.map((group) => 
                                <MenuItem value={group.id} key={group.id}>
                                    {group.sales_product_group_name}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
        },
        {
            label: 'Media',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Upload
                    customRequest={(e) => {uploadLogo(e)}}
                    listType="picture-card"
                    fileList={fileList.value.length > 0 ? fileList.value : undefined}
                    key={fileList.value.length}
                    className='customSizedUpload'
                >
                    {fileList.value.length > 0 ? null : <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>}
                </Upload>
            </Box>
        },
        {
            label: 'Periode actief',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>
                    Standaard stellen we een groep in zonder start en einddatum. Geef hier aan wanneer een artikelgroep alleen een bepaalde periode van het jaar beschikbaar is. Klik anders op ‘Volgende’.
                </Typography>
                <Box sx={{display: 'flex', gap: '16px'}}>
                    <ConfigProvider
                        theme={{
                            components: {
                            DatePicker: {
                                activeBorderColor: '#28555e',
                                cellRangeBorderColor: '#28555e',
                                hoverBorderColor: '#28555e',
                                colorPrimary: '#28555e',
                                colorBorder: '#c4c4c4',
                                borderRadius: 4,
                                colorTextPlaceholder: '#000',
                                activeShadow: 'none',
                                presetsWidth: 360,
                                zIndexPopup: 2000,
                                zIndexPopupBase: 2000,
                                zIndexBase: 2000
                            },
                            },
                        }}
                    >
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief van' onChange={(e) => {activeFrom.value = e}} value={activeFrom.value} format={'DD MMMM'} locale={locale} />
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief tot' onChange={(e) => {activeTill.value = e}} value={activeTill.value} format={'DD MMMM'} locale={locale} />
                    </ConfigProvider>
                </Box>
            </Box>
        },
        {
            label: 'Artikelen binnen groep',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <ProductsToGroup selectedProducts={selectedProducts}/>
            </Box>
        }
    ]
    return (
        <VerticalStepper open={open} title={'Groep toevoegen'} steps={steps} finalContent={finalContent} func={createGroup} resetFunc={reset}/>
    );
  };

export default ProductGroupAdd;