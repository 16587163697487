import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Collapse, Divider, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import Split from "../layout/Split";
import { apiClient } from "../../functions/Login";
import { AllergenOut, ProductOptionTypes } from "../../assets";
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import ButtonOutlined from "../buttons/ButtonOutlined";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import DragableItem from "../DragableItem";
import DeleteIcon from '@mui/icons-material/Delete';
import Alerter from "../Alerter";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface OptionsToOptiongroupProps {
  is_quantity: Signal<boolean>
  options: Signal<option[]>
}

interface option {
  name: string
  subcode: string
  option_type: ProductOptionTypes | ''
  is_inclusive: boolean
  has_quantity: boolean
  quantity_factor: string
  price_factor: string
  price_bonus: string
  char_count: string
  per_char_bonus: string
  total_char_bonus: string
  min_amount: string
  max_amount: string
  per_amount_bonus: string
  total_amount_bonus: string
  files: ('jpg' | 'png' | 'pdf' | 'doc')[]
  total_file_bonus: string
  has_allergens: boolean
  allergens: string[]
}

const OptionsToOptiongroup: React.FC<OptionsToOptiongroupProps> = ({is_quantity, options}) => {
  const [open, setOpen] = useState<boolean>(false);

  useSignals();
  const allergens: Signal<AllergenOut[]> = useSignal([]);
  const expanded: Signal<number> = useSignal(0);
  const overId: Signal<number | null> = useSignal(null);
  const draggingId: Signal<number | null> = useSignal(null);

  const changeValue = (index: number, field: string, value: any) => {
    options.value = options.value.map((option, i) => i === index ? {...option, [field]: value} : {...option}) 
  }

  const deleteOption = (index: number) => {
    if (options.value.length > 1) {
      options.value = options.value.filter((_, i) => i !== index)
    } else {
      setOpen(true);
    }
   
  }

  const newOption = () => {
    options.value = [...options.value, {
      'name': '',
      'subcode': '',
      'option_type': is_quantity.value ?  ProductOptionTypes.OPTIESELECTOR : '',
      'has_quantity': true,
      'is_inclusive': false,
      'quantity_factor': '1,00',
      'price_factor': '1,00',
      'price_bonus': '0,00',
      'char_count': '0',
      'per_char_bonus': '0,00',
      'total_char_bonus': '0,00',
      'min_amount': '0',
      'max_amount': '0',
      'per_amount_bonus': '0,00',
      'total_amount_bonus': '0,00',
      'files': [],
      'total_file_bonus': '0,00',
      'has_allergens': false,
      'allergens': []
    }]
    expanded.value = options.value.length - 1;
  }

  const checkNumeric = (input: string) => {
    const decimalRegex = /^\d+\,?\d{0,2}$/;
    
    if (input.match(decimalRegex) || input === '') {
     return true
    } 
    return false
  }

  useEffect(() => {
    apiClient().itemManagement.getAllAllergensItemsAllergensGet().then((r) => {
      allergens.value = r.items
    })
  }, [])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragOver = (event: any) => {
    if (draggingId.value !== event.over?.id)
      overId.value = event.over?.id || null;
  };

  const handleDragStart = (event: any) => {
    draggingId.value = event.active.id;
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = active.id;
      const newIndex = over.id;
      options.value = arrayMove(options.value, oldIndex, newIndex);
    }
    overId.value = null;
    draggingId.value = null;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDragCancel={() => {overId.value = null; draggingId.value = null;}}
    >
      <SortableContext
        items={options.value.map((_, i) => i)}
        strategy={verticalListSortingStrategy}
      >
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', position: 'relative'}}>
        <Alerter 
          open={open}
          setOpen={setOpen}
          text={'Kan optie niet verwijderen, een optiegroep moet minimaal 1 optie bevatten'}
          severity='warning'
        />
        {
          options.value.map((option, i) =>
            <DragableItem key={i.toString()} id={i.toString()}>  
              {({ listeners, attributes }) => (
            <Box sx={{display: 'flex', gap: '8px', alignItems: 'start'}}>
              <Accordion sx={{width: '100%', boxShadow: 'none', border: '1px solid #D5D8DC'}} key={i} expanded={expanded.value === i} onChange={() => {expanded.value === i ? expanded.value = -1 : expanded.value = i}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlinedIcon />}
                id={i.toString()}
                sx={{display: 'flex', alignItems: 'center', gap: '16px'}}
              >
               
                  <DragIndicatorOutlinedIcon sx={{mr: '8px'}} {...listeners} {...attributes}/>
              
                <Typography variant='titleMedium'>{`Optie ${i + 1}:`} {option.name}</Typography>
              </AccordionSummary>
             
              <AccordionDetails sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Divider flexItem/>
                <TextField  helperText={options.value.filter((_, ii) => i !== ii).some(o => o.name !== '' && o.name === option.name) ? 'Optienaam moet uniek zijn' : ''} error={options.value.filter((_, ii) => i !== ii).some(o => o.name !== '' && o.name === option.name)} required label="Optie" variant="outlined" value={option.name} onChange={(e) => {changeValue(i, 'name', e.currentTarget.value)}}/>
                <TextField required label="Subcode" variant="outlined" value={option.subcode} onChange={(e) => {changeValue(i, 'subcode', e.currentTarget.value)}}/>
                  { is_quantity.value === false && <>
                      <Typography variant='labelLarge' sx={{fontWeight: 'bold'}}>
                        Soort optie
                      </Typography>
                      <Typography variant='bodyLarge'>
                        Wat voor optie wil je instellen?
                      </Typography>
                      <RadioGroup
                        value={option.option_type}
                        name="option-type-picker"
                        onChange={(e) => {changeValue(i, 'option_type', e.currentTarget.value)}}
                      >
                        {Object.values(ProductOptionTypes).map((type) =>
                            <FormControlLabel value={type} control={<Radio required={option.option_type === ''} />} label={type} />
                        )}
                      </RadioGroup>
                    </>
                    }
                  <Collapse in={option.option_type !== ''}>
                    <Box sx={{backgroundColor: '#F2F3F4', borderRadius: '16px', p: '16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                      <Typography variant='labelLarge' sx={{fontWeight: 'bold'}}>
                        {
                          option.option_type === ProductOptionTypes.OPTIESELECTOR ? 'Optieselector' : 
                          option.option_type === ProductOptionTypes.VRIJ_TEKSTVELD ? 'Vrij tekst veld' : 
                          option.option_type === ProductOptionTypes.VRIJ_NUMERIEK_VELD ? 'Vrij numeriek veld' : 
                          'Bestand upload'
                        }
                      </Typography>
                      <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                        <Typography variant='bodyMedium'>Prijstoeslag incl. btw opvoeren</Typography>
                        <Tooltip title={'De btw wordt overgenomen van het artikel waarop de btw van toepassing is. Bij het opvoeren van prijstoeslagen incl. btw is het dus mogelijk dat de prijs excl. btw hoger of lager uitvalt op verschillende artikelen. Bijvoorbeeld wanneer de optie op een artikel met laag-btw tarief en op een artikel met hoog-btw tarief wordt toegevoegd.'}>
                          <InfoOutlinedIcon />
                        </Tooltip>
                        <Switch onChange={(e) => {changeValue(i, 'is_inclusive', !option.is_inclusive)}} value={option.is_inclusive} sx={{ml: 'auto'}} color="secondary" />
                      </Box> 
                      <Divider /> 
                      {
                        (option.option_type === ProductOptionTypes.OPTIESELECTOR || is_quantity.value === true) ?
                        <>
                          <Collapse in={is_quantity.value}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                              <Typography variant='bodyLarge'>Hoeveelheidsfactor</Typography>
                              <TextField required label="Hoeveelheidsfactor" variant="outlined" value={option.price_factor} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 1  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'price_factor', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'price_factor', e.currentTarget.value)}}/>
                              <Typography variant='bodyLarge'>Prijsfactor</Typography>
                              <TextField required label="Prijsfactor" variant="outlined" value={option.quantity_factor} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 1  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'quantity_factor', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'quantity_factor', e.currentTarget.value)}}/>
                            </Box>
                          </Collapse>
                          <Typography variant='bodyLarge'>{'Prijstoeslag ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')}</Typography>
                          <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Prijstoeslag ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.price_bonus} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'price_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'price_bonus', e.currentTarget.value)}}/>
                        </> :
                        option.option_type === ProductOptionTypes.VRIJ_TEKSTVELD ?
                        <>
                            <Typography variant='bodyLarge'>Hoeveel karakters mag de klant maximaal invoeren?</Typography>
                            <TextField required label={'Aantal karakters'} variant="outlined" value={option.char_count} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'char_count', temp.toFixed(0).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'char_count', e.currentTarget.value)}}/>
                            <Typography variant='bodyLarge'>{'Prijstoeslag ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')}</Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                              <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Per karakter ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.per_char_bonus} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'per_char_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'per_char_bonus', e.currentTarget.value); changeValue(i, 'total_char_bonus', '0,00')}}/>
                              <Typography variant='labelLarge' sx={{fontWeight: '700'}}>OF</Typography>
                              <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Totaal ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.total_char_bonus} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'total_char_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'total_char_bonus', e.currentTarget.value); changeValue(i, 'per_char_bonus', '0,00')}}/>
                            </Box>
                        </> : 
                        option.option_type === ProductOptionTypes.VRIJ_NUMERIEK_VELD ?
                        <>
                          <Typography variant='bodyLarge'>Welk aantal mag de klant bestellen?</Typography>
                          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <TextField required label={'Minimaal aantal'} variant="outlined" value={option.min_amount} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'min_amount', temp.toFixed(0).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'min_amount', e.currentTarget.value)}}/>
                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>tot</Typography>
                            <TextField required label={'Maximaal aantal'} variant="outlined" value={option.max_amount} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'max_amount', temp.toFixed(0).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'max_amount', e.currentTarget.value)}}/>
                          </Box>
                          <Typography variant='bodyLarge'>{'Prijstoeslag ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')}</Typography>
                          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Per stuk ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.per_amount_bonus} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'per_amount_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'per_amount_bonus', e.currentTarget.value); changeValue(i, 'total_amount_bonus', '0,00')}}/>
                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>OF</Typography>
                            <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Totaal ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.total_amount_bonus} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'total_amount_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'total_amount_bonus', e.currentTarget.value); changeValue(i, 'per_amount_bonus', '0,00')}}/>
                          </Box>
                        </> :
                        <>
                          <Typography variant='bodyLarge'>Welke bestandsformaten mag de klant uploaden?</Typography>
                          <Split 
                        key={option.has_allergens.toString()}
                        gap={8}
                        left={
                          <>
                           <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                              <Checkbox checked={option.files.includes('jpg')} onChange={(e) => {option.files.includes('jpg') ? changeValue(i, 'files', option.files.filter(a => a !== 'jpg')) : changeValue(i, 'files', [...option.files, 'jpg'])}}/>
                              <Typography variant='bodyMedium'>
                                  JPEG / JPG
                              </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                              <Checkbox checked={option.files.includes('pdf')} onChange={(e) => {option.files.includes('pdf') ? changeValue(i, 'files', option.files.filter(a => a !== 'pdf')) : changeValue(i, 'files', [...option.files, 'pdf'])}}/>
                              <Typography variant='bodyMedium'>
                                  PDF
                              </Typography>
                            </Box>
                          </>
                        }
                        right={
                          <>
                           <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                              <Checkbox checked={option.files.includes('png')} onChange={(e) => {option.files.includes('png') ? changeValue(i, 'files', option.files.filter(a => a !== 'png')) : changeValue(i, 'files', [...option.files, 'png'])}}/>
                              <Typography variant='bodyMedium'>
                                  PNG
                              </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                              <Checkbox checked={option.files.includes('doc')} onChange={(e) => {option.files.includes('doc') ? changeValue(i, 'files', option.files.filter(a => a !== 'doc')) : changeValue(i, 'files', [...option.files, 'doc'])}}/>
                              <Typography variant='bodyMedium'>
                                  DOC / DOCX
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                          <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label={'Prijstoeslag ' + (option.is_inclusive ? '(incl. btw)' : '(excl. btw)')} variant="outlined" value={option.total_file_bonus} onBlur={(e) => {let temp = Number.parseFloat(e.currentTarget.value.replace(',', '.')); changeValue(i, 'total_file_bonus', temp.toFixed(2).toString().replace('.', ','))}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) changeValue(i, 'total_file_bonus', e.currentTarget.value)}}/>
                        </>
                      }
                      <Box sx={{display: 'flex'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                          <Typography variant='bodyLarge'>Optie bevat allergenen</Typography>
                          <Typography variant='bodySmall'>U bent zelf verantwoordelijk voor de juistheid en volledigheid van de allergenen en dieet informatie bij het product.</Typography>
                        </Box>
                        <Switch onChange={(e) => {changeValue(i, 'has_allergens', !option.has_allergens)}} value={option.has_allergens} sx={{ml: 'auto'}} color="secondary" />
                      </Box>
                      <Collapse in={option.has_allergens}>
                      <Split 
                        key={option.has_allergens.toString()}
                        gap={8}
                        left={
                          <>
                            {
                            allergens.value.slice(0, allergens.value.length / 2).map((allergen) =>
                              <Box key={allergen.id} sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={option.allergens.includes(allergen.allergen_name)} onChange={(e) => {option.allergens.includes(allergen.allergen_name) ? changeValue(i, 'allergens', option.allergens.filter(a => a !== allergen.allergen_name)) : changeValue(i, 'allergens', [...option.allergens, allergen.allergen_name])}}/>
                                <Typography variant='bodyMedium'>
                                    {allergen.allergen_name}
                                </Typography>
                              </Box>
                            )
                            }
                          </>
                        }
                        right={
                          <>
                           {
                            allergens.value.slice(allergens.value.length / 2, allergens.value.length).map((allergen) =>
                              <Box key={allergen.id} sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={option.allergens.includes(allergen.allergen_name)} onChange={(e) => {option.allergens.includes(allergen.allergen_name) ? changeValue(i, 'allergens', option.allergens.filter(a => a !== allergen.allergen_name)) : changeValue(i, 'allergens', [...option.allergens, allergen.allergen_name])}}/>
                                <Typography variant='bodyMedium'>
                                    {allergen.allergen_name}
                                </Typography>
                              </Box>
                            )
                            }
                          </>
                        }
                      />
                      </Collapse>
                    </Box>
                  </Collapse>
                </AccordionDetails>
              </Accordion>
              <IconButton sx={{top: '4px'}} onClick={() => {deleteOption(i)}}>
                <DeleteIcon />
              </IconButton>
            </Box>
            )}
            </DragableItem>
            
          )
        }
        <Box sx={{display: 'flex', ml: 'auto'}}>
          <ButtonOutlined Icon={AddOutlinedIcon} text='Nog een optie toevoegen' onClick={newOption}/>
        </Box>
        <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
          Je kunt de opties in de gewenste volgorde slepen. De bovenste optie wordt dan als eerst getoond en wordt als standaard ingesteld.  
        </Typography>
      </Box>
      
      </SortableContext>
    </DndContext>
  );
};

export default OptionsToOptiongroup; 